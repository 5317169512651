<template>
  <div class="cad__button-wrapper">
    <tippy v-if="isDisabled" to="buttonCad" :content="tooltip" arrow="true" maxWidth="316px">
    </tippy>
    <div name="buttonCad" style="height: 100%">
      <button :disabled="isDisabled" class="cad__button" @click="startConnection()">
        <img src="@/assets/img/nav/cad-tool.svg" alt="cad" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { cadUrl, cadQuery } from '@/api'
export default {
  name: 'CadButton',
  props: {
    isDisabled: Boolean,
    tooltip: String,
    name: String,
    placement: String,
    currentJunction: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isEditable: false,
    listener: null
  }),
  methods: {
    ...mapActions({
      openWebsocketConnection: 'openWebsocketConnection',
      closeWebsocketConnection: 'closeWebsocketConnection',
      sendStepToProxy: 'sendStepToProxy',
      handleMessage: 'handleMessage',
      updateOpenedTab: 'updateOpenedTab'
    }),
    async startConnection() {
      if (this.socket && this.socket?.socket?.isOpened) {
        await this.setSocketListeners()
        await this.sendStep()
      } else {
        try {
          await this.openWebsocketConnection(this.socketId)
          if (this.socket?.socket?.isOpened) {
            this.openCad()
            this.setSocketListeners()
          }
        } catch (error) {
          this.$notify({
            group: 'cad',
            type: 'error',
            title: this.$t('message.cad.error')
          })
        }
      }
    },
    async sendStep() {
      try {
        await this.sendStepToProxy({
          currentJunction: this.currentJunction,
          sectorIndex: this.sectorIndex
        })
      } catch (error) {
        this.$notify({
          group: 'cad',
          type: 'error',
          title: this.$t('message.cad.error')
        })
      }
    },
    openCad() {
      const cadTab = window.open(`${cadUrl}${cadQuery}${this.socketId}`, '_blank')
      cadTab.focus()
      this.updateOpenedTab(cadTab)
    },
    setSocketListeners() {
      this.socket.onMessage(event => this.setValueFromSocket(event))
      this.socket.onClose(event => {
        this.$notify({
          group: 'cad',
          type: 'error',
          title: this.$t('message.cad.error')
        })
      })
      this.listener = this.toggleSocket.bind(this)
      window.addEventListener('message', this.listener)
      this.socket.setWindowListener(this.listener)
    },
    async removeSocketListeners() {
      this.socket.socket.removeAllListeners()
      this.socket.closeWindowListener()
      window.removeEventListener('message', this.listener)
      this.listener = null
    },
    async closeSocket() {
      await this.removeSocketListeners()
      await this.closeWebsocketConnection()
      this.openedTab.close()
    },
    async toggleSocket(event) {
      if (event.data === 'socket-opened') {
        await this.sendStep()
      }
      if (event.data === 'socket-failed') {
        await this.closeSocket()
      }
    },
    setValueFromSocket(event) {
      this.handleMessage({
        ...event,
        sectorIndex: this.sectorIndex,
        junctionIndex: this.junctionIndex
      })
    }
  },
  async beforeDestroy() {
    // if (this.socket) {
    //   await this.removeSocketListeners()
    // }
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors,
      socket: state => state.websocket,
      socketId: state => state.websocketId,
      openedTab: state => state.openedTab
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    }
  }
}
</script>

<style lang="sass">
.cad__button
  &-wrapper
    height: 100%
  font-family: $main-font
  font-style: normal
  font-weight: bold
  display: flex
  align-items: center
  padding: 0 rem(10)
  +media((margin: (320: 0 rem(10) 0 0, 960: 0 rem(10))))
  border-radius: rem(4)
  outline: none
  box-shadow: none
  border: none
  cursor: pointer
  flex-direction: row
  transition: .5s
  text-decoration: none
  height: 100%
  background-color: $white
  opacity: .5
  &:hover
    opacity: 1
  &:disabled
    opacity: .2
    cursor: no-drop
</style>
